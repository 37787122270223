.rotator {
    transform-origin: center center 200px;
    transform-style: flat;
    animation: rotate-hor 5s ease-out infinite alternate;
    height: 800px;
    .image-effects:hover {
        transform-origin: center bottom;
        transform: scale(1.005);
        transition: transform 250ms ease-in-out;
    }
    &.r1 {
        .image-effects {
            &:nth-child(1) img {
                transform: translate3d(-20%, 0, 0) scale(1.75);
            }
        }
    }
    &.r2 {
        .image-effects {
            &:nth-child(1) img {
                transform: translate3d(0, 0, 0);
            }
            &:nth-child(2) img {
                transform: translate3d(0, 0, 0);
            }
        }
    }
    &.r3 {
        .image-effects {
            &:nth-child(1) img {
                transform: translate3d(-25%, -30%, 0) scale(1.4);
            }
            &:nth-child(2) img {
                transform: translate3d( 25%, -110%, 0);
            }
            &:nth-child(3) img {
                transform: translate3d( -25%, -165%, 0);
            }
        }
    }
    &.r4 {
        .image-effects {
            &:nth-child(1) img {
                transform: translate3d(-25%, -30%, 0) scale(1.4);
                z-index: 1;
            }
            &:nth-child(2) img {
                transform: translate3d( 10%, -125%, 0);
                z-index: 3;
            }
            &:nth-child(3) img {
                transform: translate3d( -25%, -165%, 0);
                z-index: 4;
            }
            &:nth-child(4) {
                transform-origin: center top;
                img {
                    transform: translate3d( 60%, -400%, 0);
                    z-index: 2;
                }
            }
        }
    }
}

@keyframes rotate-hor {
    0% {
        transform: translateZ(-288px) rotateY(-3deg) rotateX(15deg) scale(.5)
    }
    100% {
        transform: translateZ(-288px) rotateY(3deg) rotateX(15deg) scale(.5)
    }
}