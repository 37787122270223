.sitemap-container {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 3rem 0;
  position: relative;

  h1 {
    margin-bottom: unset;
  }

  .content {
    padding: 1rem;
    background-color: black;
    color: white;
    font-weight: 600;
    max-height: 40vh;
    overflow-y: scroll;
    cursor: pointer;

    .logo-ball {
      margin: auto;
    }

    &:after {
      position: absolute;
      display: block;
      right: 3rem;
      bottom: 5rem;
      font-size: 20pt;
      content: "\f0c5";
      font-family: 'Font Awesome 5 Pro';
      transition: transform 400ms ease-in-out;
      z-index: 5;
      transform: scale(1);

      &:hover {
        transform: scale(1.3);
        transform-origin: center;
      }
    }

    code {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;

      /* Non-prefixed version, currently */
      &:before {
        content: '<?xml version="1.0" encoding="UTF-8"?>';
      }
    }

    urlset {
      display: flex;
      flex-direction: column;

      &:before {
        content: "<urlset>"
      }

      &:after {

        content: "</urlset>"
      }

      url {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        &:before {
          content: "<url>"
        }

        &:after {

          content: "</url>"
        }

        loc,
        lastmod {
          margin-left: 1rem;
        }

        loc {

          &:before {
            content: "<loc>"
          }

          &:after {

            content: "</loc>"
          }
        }

        lastmod {

          &:before {
            content: "<lastmod>"
          }

          &:after {

            content: "</lastmod>"
          }
        }
      }
    }
  }

  input {
    display: none;
    overflow: hidden;
  }
}

.App section.container.xml {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}