section.footer {
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .logo {
        margin: auto;
    }

    ul {
        margin-top: 1rem;
        padding-left: unset;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    a {
        text-decoration: none;
    }
}