@import './theme/theme-variables.scss';

@mixin background-opacity($color,
    $opacity: 0.3) {
    background: $color;
    /* The Fallback */
    background: rgba($color, $opacity);
}

body {
    font-size: 12px;
    overflow-x: hidden;
}

.App {
    background-color: #ecf0f5;
    min-height: 100vh;

    &>section {
        display: flex;

        &.row {
            flex-direction: row;
        }

        &.col {
            flex-direction: column;
        }

        &:last-of-type {
            padding-bottom: 20vh;
        }
    }

    &>section>.container {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        margin-bottom: 1rem;

        &.center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:first-of-type {
            margin-top: 1rem;
        }
    }

    section.loading {
        .container {
            min-height: 50vh;
        }
    }

    &>section.header>.container {
        margin: auto;
    }

    &>section {
        padding: 0 1rem;
    }

    .container.card {
        padding: 1rem;
        box-sizing: border-box;
    }
}


.flex {
    display: flex;

    &.row {
        flex-direction: row;
    }

    &.col {
        flex-direction: column;
    }

    &.a-center {
        align-items: center;
    }

    &.j-center {
        justify-content: center;
    }
}

h1 {
    margin-top: 0;
    font-size: 2.488rem;
}

h2 {
    font-size: 2.074rem;
}

h3 {
    font-size: 1.728rem;
}

h4 {
    font-size: 1.44rem;
}

h5 {
    font-size: 1.2rem;
}

small,
.text_small {
    font-size: 0.833rem;
}

p {
    margin-bottom: 1rem;
}

.button {
    padding: .25rem 1rem;
    border: none;
    background-color: #282828;
    color: white;
    margin: 5px;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    transition: box-shadow 150ms ease-out;

    &:hover {
        box-shadow: 0 0 0 3px rgba(93, 93, 136, .4);
    }

    &.purple {
        background-color: $kd-purple;
        color: white;
    }

    &.clean {
        background-color: transparent;
        color: #282828;
    }

    &.right {
        margin-left: auto;
    }

    &.disabled {
        opacity: .5;
        box-shadow: 0 0 0 0px rgba(93, 93, 136, 0.4);
        cursor: default;

        &:hover {
            box-shadow: 0 0 0 0px rgba(93, 93, 136, 0.4);
        }
    }
}

.logo {
    width: 50px;
}

section.cta {
    background-color: $kd-purple;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem;
    overflow: hidden;

    .container {
        position: relative;

    }

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;

        p {
            text-align: center;
        }

        h2 {
            text-align: center;
            margin-bottom: unset;
        }

        p~h2 {
            margin-top: 0;
        }

        a:last-child {
            margin-bottom: 1.5rem;
        }
    }

    .button {
        background-color: #1f1f30;

        &:hover {
            box-shadow: 0 0 0 3px rgba(35, 35, 104, 0.4);
        }
    }

    a {
        text-decoration: unset;
    }
}

.button-con {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hide {
    opacity: 0;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    padding: unset !important;
    margin: unset !important;
}

.status-container {
    display: flex;
    align-items: center;
    position: relative;
    grid-column: 1 / span 2;
    font-family: 'Font Awesome 5 Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: unset;

    i {
        margin-right: .5rem;
    }

    p {
        width: 100%;
        display: flex;
        align-items: center;
        margin: unset;
    }

    .close {
        position: absolute;
        right: 2rem;
        color: white;
        font-size: 1rem;
        cursor: pointer;
        font-family: 'Font Awesome 5 Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-style: unset;
        z-indeX: 10;
    }

    p.ok {
        background-color: $green;
        border: 3px solid rgba(255, 255, 255, .5);
        color: white;
        font-weight: 700;
        padding: 1rem 2rem;
    }

    p.danger {
        background-color: $red;
        border: 3px solid rgba(255, 255, 255, .5);
        color: white;
        font-weight: 700;
        padding: 1rem 2rem;
    }
}