.testimonials {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .testimonial {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: calc(25px + 2vw) calc(10px + 3vw);
    order: 1;

    p:last-of-type {
      margin-bottom: unset;
    }

    p:first-of-type {
      margin-top: unset;
    }

    .content {
      font-size: 1rem;
      margin-bottom: auto;
    }

    .author {
      margin-top: 1rem;
      text-align: right;
    }

    .position {
      text-align: right;
      margin-top: .25rem;
    }

    &.long {
      grid-row: 1 / span 2;

      @media screen and (min-width: 768px) {
        grid-column: 2;
      }

    }

    &.short {
      @media screen and (min-width: 768px) {
        grid-column: 2;
        grid-row: 3;
      }
    }

    &.med {
      order: 0;
    }
  }
}