@import '../../app/theme/theme-variables.scss';

.App.topic {
    background-color: #ecf0f5;
    font-size: 10pt;

    @media screen and (max-width: 800px) {
        .badges.too-long {
            max-height: unset;

            &:after,
            &:before {
                display: none;
            }
        }
    }

}

.topic-showcase {
    grid-column: 1 / span all;

    &.container {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        >.title {
            grid-column: 1 / span all;
            text-align: center;
            max-width: 800px;
            padding: unset;
            margin: auto;
        }

        h1 {
            margin-bottom: 0;
        }

        h5 {
            margin-top: 0;
            margin-bottom: 0;
        }

        h1,
        p {
            z-index: 1;
        }

        .badge {
            width: unset;
        }

    }
}