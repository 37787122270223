.logo-ball {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  background-position: top left;
  background-size: 314.159%;
  background-repeat: repeat-x;

  .pattern,
  .repeat,
  .again {
    position: absolute;
    width: 314.159%;
    top: 0;
    overflow: visible;
    z-index: 1;
  }

  .again {
    z-index: 0;
  }

  .pattern {
    left: 0;
  }

  .repeat {
    left: 314.159%;
  }

  .repeat,
  .pattern {
    animation: spin 500ms linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}