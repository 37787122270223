@import '../../app/theme/theme-variables.scss';

.landing {
    background-color: #f9f9fa;

    .search-con .badges {
        max-height: 0;
    }

    section.header {
        display: none;
    }

    >section {
        padding: 1rem;
    }

    section.portfolio-cards {
        box-sizing: border-box;

        .container {
            margin-top: unset;

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 500px) {
                grid-template-columns: 1fr;
            }
        }
    }

    &>section:nth-of-type(2n) {
        background-color: #ecf0f5;
    }

    section.cta {
        background-color: $kd-purple;

    }

    .title {
        h2 {
            text-align: center;
        }
    }


    section.chooser,
    section.featured {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding-bottom: 10vh;
        padding-top: 10vh;
        position: relative;
        box-sizing: border-box;
        gap: 1rem;

        .top {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 100%;
            text-align: center;

            h1 {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: .8rem;
                flex-wrap: wrap;
                margin-bottom: 0;
            }

            h5 {
                margin-top: 0;
            }

            a {
                text-decoration: none;
                transition: color, padding-left 400ms ease-in-out;
                position: relative;
                padding-left: 0;
                overflow: hidden;
                display: inline-block;
                color: #58B7E0;

                &:first-of-type {
                    color: #92C756;
                }

                &:before {
                    display: inline-block;
                    content: "/";
                    position: absolute;
                    left: -2rem;
                    top: 10rem;
                    opacity: 0;
                }

                &:hover {
                    color: $kd-purple;
                    padding-left: 1.5rem;

                    &:before {
                        animation: slash-move 400ms ease-in-out forwards;
                    }
                }
            }

            .logo {
                max-width: 15vh;
                min-width: 150px;
                width: 100%;
                margin-bottom: 2rem;
                min-height: 142.5px;
                opacity: 0;
                transition: opacity 500ms ease-in-out;
            }
        }

        .topic-showcase.logo-design .search-results {
            grid-template-columns: repeat(6, minmax(0, 1fr));

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media screen and (max-width: 500px) {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

        .button-con {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .button {
                white-space: nowrap;
            }
        }



        .search-con {
            margin-bottom: .5rem;
            border-radius: 2px;
            box-shadow: 0 0 0 1px rgba(93, 93, 136, .1);
            transition: box-shadow 150ms ease-out;
            display: flex;
            flex-direction: column;
            max-width: 450px;
            position: relative;


            // if search recommendation badges are visible hide below tooltip
            .badges.show~.sugg-but-con {
                &:before {
                    display: none;
                }
            }

            // if input has focus show tooltip after 2 seconds
            input:focus~.sugg-but-con {
                &:before {
                    display: inline-block;
                    position: absolute;
                    right: 70px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: 'Font Awesome 5 Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                    font-weight: 400;
                    padding-bottom: 2px;
                    content: "Not sure? \f356";
                    opacity: 0;
                    animation: point-right 1000ms ease-in-out 2000ms alternate infinite;
                }
            }

            &:hover,
            &:active,
            &:focus {
                .search-but svg {
                    animation: angled-spin 2000ms ease-in-out infinite;
                }
            }

            &:hover {
                box-shadow: 0 0 0 1px rgba(93, 93, 136, .4);
            }

            .search-input {
                display: grid;
                grid-template-columns: 1fr 2.5rem;
                grid-template-rows: auto;
                overflow: hidden;
                position: relative;
                height: 40px;

                &.show {
                    height: unset;
                    grid-template-rows: auto 1fr;
                }

                .form-element {
                    width: 100%;
                    padding: .5rem .75rem;
                    border: none;
                    overflow: hidden;
                    grid-column: 1;
                    grid-row: 1;
                    outline: none;
                }

                .sugg-but-con,
                .search-but,
                .form-element {
                    height: 40px;
                    max-height: 40px;
                    box-sizing: border-box;
                }

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: .5rem .75rem;
                    padding-left: .825rem;
                    margin: .25rem 0;

                    svg {
                        max-width: 10px;
                        width: 100%;
                    }
                }

                &>.button {
                    margin: 0;
                }

                .badges {
                    background-color: white;
                    max-height: 0;
                }

                .badges.show {
                    animation: delay-show-wiz 200ms forwards 100ms;
                    justify-content: flex-start;
                    max-height: 0vh;


                    &+.sugg-but-con .sugg-but {
                        background-color: #f7ae3d;
                        border: 1px solid #7f3f20;

                        svg {
                            color: #7f3f20;
                        }

                        &:hover {
                            box-shadow: 0 0 0 3px rgba(234, 198, 9, 0.4);

                            .mario {
                                position: absolute;
                                max-width: 1.5rem;
                                max-height: 2rem;
                                width: 1.5rem;
                                transform: translateY(2.5rem);
                                animation: mario-ease-up 2000ms ease-out forwards 100ms;
                            }
                        }

                        &:active {
                            box-shadow: 0 0 0 3px rgba(234, 198, 9, 0.4);
                            transform: translateY(-.5rem);
                            margin-bottom: .5rem;

                            .mario {
                                transform: translateY(1.1rem);
                                max-width: 1.5rem;
                                max-height: 2rem;
                                width: 1.5rem;
                                opacity: 1;
                                animation: unset;
                            }
                        }
                    }
                }

                .sugg-but-con {
                    background-color: unset;
                    border: none;
                    height: 100%;
                    width: 35px;
                    margin-left: auto;
                    box-sizing: border-box;
                    grid-column: 1;
                    grid-row: 1;
                    outline: none;
                    display: flex;

                    .sugg-but {
                        width: 1rem;
                        padding: .25rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        margin-right: 10px;
                        position: relative;

                        .questionmark {
                            color: white;
                        }

                        .mario {
                            position: absolute;
                            max-width: 0;
                            max-height: 0;
                            transform: translateY(1.5rem);
                            opacity: 0;
                            cursor: pointer;
                        }

                        .svg {
                            pointer-events: none
                        }
                    }
                }
            }
        }

        .search-results {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 1rem;
            grid-column: 1 / span all;
            align-items: stretch;
            padding: 1rem 0;
            max-width: 1200px;
            width: 100%;

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr 1fr;

                .excerpt p {
                    max-height: 10rem;
                }
            }

            @media screen and (max-width: 500px) {
                display: flex;
                flex-direction: column;
            }

            >.search-results {
                grid-column: 1 / span all;
                padding: unset;

                @media screen and (max-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }

                .title {
                    grid-column: 1 / span all;

                    h1 {
                        margin-bottom: 0;
                    }
                }

            }

            .card.badges {
                grid-row: 1;
                grid-column: 1 / span all;
            }

            .loader~.result {
                display: none;
            }

            .loader~a {
                display: none;
            }

            .loader {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                margin: auto;
                grid-column: 1 / span all;
                height: 50px;

                i {
                    font-size: 20pt;
                    animation: rotate 500ms infinite;
                    transform-origin: center center;
                }

                @keyframes rotate {
                    0% {
                        transform-origin: center center;
                        transform: rotate(0deg)
                    }

                    100% {
                        transform-origin: center center;
                        transform: rotate(360deg)
                    }
                }

                &.hidden {
                    display: none;
                }

                &.loading {
                    background-color: orange;
                }

                &.success {
                    background-color: green;
                }

                &.error {
                    background-color: red;
                }
            }
        }

        .scroll-ind {
            position: absolute;
            bottom: 0;

            svg {
                display: block;
                width: 10px;
                margin: auto;
                margin-bottom: 1rem;
                animation: spin-jump 2000ms ease-in-out 500ms infinite;
            }
        }
    }

    section.featured {
        min-height: unset;
    }
}

@keyframes mario-ease-up {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    100% {
        transform: translateY(2.0rem);
        opacity: 1;
    }
}

@keyframes delay-show-wiz {
    0% {
        max-height: 0vh;
    }

    100% {
        max-height: 40vh;
        overflow: hidden;
        padding: 1rem;
        overflow: auto;
    }
}

@keyframes spin-jump {
    0% {
        transform: rotateY(0) translateY(0);
    }

    50% {
        transform: rotateY(180deg) translateY(-5px);
    }

    100% {
        transform: rotateY(360deg) translateY(0px);
    }
}

@keyframes angled-spin {
    0% {
        transform: rotate3d(0, 0, 0, 0);
    }

    50% {
        transform: rotate3d(.5, .5, 0, 180deg);
    }

    100% {
        transform: rotate3d(.5, .5, 0, 360deg);
    }
}


@keyframes slash-move {
    0% {
        opacity: 0;
        left: 5rem;
        top: -10rem;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        left: 0rem;
        top: 0rem;
    }
}

@keyframes point-right {
    0% {
        right: 70px;
        opacity: 0;
    }

    100% {
        right: 75px;
        opacity: 1;
    }
}