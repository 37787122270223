.showcase-container {
    display: grid;
    grid-template-columns: .6666fr .3333fr;
    box-sizing: border-box;
    margin-top: 1rem;
    &.small {
        width: 80%;
        margin: auto;
    }
    &.alternate {
        grid-template-columns: .3333fr .6666fr;
        >div:nth-of-type(1) {
            order: 1;
        }
        >div:nth-of-type(2) {
            order: 0;
        }
        .card-container {
            padding: 4rem;
            padding-right: 0;
            .card {
                transform: translateX(10%);
                z-index: 1;
            }
        }
    }
    img {
        max-width: 100%;
    }
    .card-container {
        display: flex;
        align-items: center;
        padding: 4rem;
        text-align: center;
        padding-left: 0;
        .card {
            transform: translateX(-10%);
            h3 {
                margin-top: 0;
            }
        }
    }
}