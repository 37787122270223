.cloud {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr 1fr;
  position: absolute;
  width: 100%;
  min-height: 100%;
  grid-gap: 0 1rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(#5d5d88 30%, rgba(93, 93, 136, 0));
    z-index: 1;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(8, minmax(0, 1fr));

    &:after {
      background: radial-gradient(#5d5d88 50%, rgba(93, 93, 136, 0));
    }


  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));

    &:after {
      background: radial-gradient(#5d5d88 40%, rgba(93, 93, 136, 0));
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    &:after {
      background: radial-gradient(#5d5d88 60%, rgba(93, 93, 136, 0));
    }
  }

  img {
    height: 100px;
    align-self: center;
    justify-self: center;
    filter: invert(1) saturate(0);
    mix-blend-mode: screen;

    &:nth-of-type(3n-2) {
      margin-top: -2vh;
      transform: scale(1.1) rotateZ(-10deg);
    }

    &:nth-of-type(3n-1) {
      margin-top: 5vh;
      transform: scale(.5) rotateZ(-5deg);
    }

    &:nth-of-type(3n) {
      margin-top: 0vh;
      transform: scale(.8) rotateZ(10deg);
    }
  }

}

.small-cloud {
  .cloud:after {
    background: radial-gradient(#5d5d88 0%, rgba(93, 93, 136, 0));
  }
}