.badges {
    display: flex;
    grid-row: 2;
    grid-column: span 2;
    padding: 0 1rem;
    box-sizing: border-box;
    flex-wrap: wrap;
    max-width: 100%;
    transition: max-height 200ms ease-out;
    transition: padding 50ms ease-out;
    overflow: hidden;
    gap: 0 .25rem;
    justify-content: center;

    h5 {
        width: 100%;
        margin: 0;
        margin-bottom: .5rem;
        margin-top: .5rem;
        margin-left: .25rem;

        &:first-child {
            margin-top: 0;
        }
    }

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.too-long {
        max-height: 80px;
        position: relative;
        transition: max-height 250ms ease-in-out;

        &:hover,
        &:active,
        &:focus {
            max-height: 2000px;

            &:before,
            &:after {
                opacity: 0;
            }
        }

        &:before {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            height: 20px;
            bottom: 0;
            background: linear-gradient(rgba(255, 255, 255, 0), white);
            z-index: 20;
            transition: opacity 250ms ease-in-out;
        }

        &:after {
            transition: opacity 250ms ease-in-out;
            content: "View All tags";
            position: absolute;
            white-space: nowrap;
            width: auto;
            height: auto;
            display: block;
            background-color: white;
            padding: .25rem 1rem;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            z-index: 23;
        }
    }

    &:after {
        display: block;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .badge {
        justify-self: center;
        align-self: flex-start;
    }

    .badge {
        grid-column: span 2;
        width: auto;
        position: relative;
        cursor: pointer;

        &.button {
            padding: unset !important;
            height: 28px;
            margin: .25rem .0rem;
        }

        .badge1 {
            position: relative;
            z-index: 10;
            transition: 150ms opacity ease-out;
        }

        .badge2 {
            position: absolute;
            top: 0;
            left: 0;
        }

        &.empty {
            padding: .15rem 1rem !important; // overides important from .button.badge for .empty
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            box-sizing: border-box;
            background-color: #25252b;
            color: white;
            font-weight: 700;
            z-index: 10;
        }

        &:hover .badge1 {
            opacity: 0;
        }
    }
}