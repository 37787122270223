.App.beehive {
    background-color: #282828;
    .beehive.container {
        display: grid;
        grid-template-columns: 500px minmax(0, 1fr);
        grid-template-rows: calc(50vh - 2.025rem) calc(50vh - 2.025rem);
        max-width: 100vw;
        height: calc(100vh - 4.05rem);
        max-height: calc(100vh - 4.05rem);
        min-height: calc(100vh - 4.05rem);
        overflow: hidden;
        position: relative;
        &.popout {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: minmax(0, 1fr);
            .editor {
                display: none;
            }
            .images {
                grid-column: 1;
                height: 100%
            }
            .questions {
                grid-column: 2;
            }
        }
        .images {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background-color: #282828;
            position: relative;
            .button-con {
                position: absolute;
                top: .25rem;
                right: 0;
            }
            .images-container {
                overflow: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 100%;
                div {
                    max-width: 100%;
                    flex-grow: 1;
                    border: solid 2px black;
                    cursor: pointer;
                    .image {
                        max-width: 100%;
                        object-fit: cover;
                        max-height: 200px;
                        min-height: 100%;
                        min-width: 100%;
                        background-color: black;
                        &:hover {
                            object-fit: contain;
                        }
                    }
                }
            }
            h3 {
                width: 100%;
                background-color: #151515;
                padding: .25rem 1rem;
                color: white;
                margin: 0;
                box-sizing: border-box;
            }
        }
        .questions {
            overflow: auto;
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            grid-column: 1;
            grid-row: 2;
            padding: 1rem 1rem;
            justify-content: flex-start;
            background-color: #efefef;
            position: relative;
            .button-con {
                position: absolute;
                top: .75rem;
                right: 0;
            }
            h3,
            p {
                margin: 0;
            }
            h3 {
                margin-bottom: 1rem;
            }
            .question {
                padding: .5rem;
                margin-left: -.25rem;
                margin-right: -.25rem;
                margin-bottom: 0;
                margin-top: 0;
                box-sizing: border-box;
                display: flex;
                transition: all 150ms ease-in-out;
                box-shadow: 0 0 0 3px rgba(93, 93, 136, 0.0);
                &:last-child {
                    margin-bottom: 5rem;
                }
                .text {
                    flex-grow: 1;
                }
                .tone-con {
                    display: flex;
                    .tone {
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        height: auto;
                        max-height: 1.25rem;
                        min-height: 1.25rem;
                        margin: 0rem .1rem;
                        background-color: unset;
                        padding: 0;
                        &:before,
                        &:after {
                            transition: all 250ms ease-in-out;
                        }
                    }
                    .external,
                    .copy {
                        height: 0;
                        width: 0;
                        padding: 0;
                        opacity: 0;
                        svg {
                            height: 1rem;
                            max-height: 1rem;
                        }
                        color: white;
                    }
                }
                &:hover {
                    box-shadow: 0 0 0 3px rgba(93, 93, 136, 0.4);
                    display: grid;
                    grid-template-rows: auto 1fr;
                    grid-row-gap: .5rem;
                    padding: .5rem .25rem .5rem .75rem;
                    background-color: white;
                    .text {
                        grid-column: span 3;
                        font-size: 1rem;
                        padding: 0.25rem;
                        font-weight: 600;
                    }
                    .tone-con {
                        grid-column: span 3;
                        grid-row: 2;
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: -.25rem;
                        margin-right: -.25rem;
                        .external,
                        .copy {
                            height: 100%;
                            width: auto;
                            opacity: 1;
                            padding: 0;
                            min-width: 40px;
                            height: 40px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            border-radius: 50px;
                            margin: .25rem;
                            justify-content: center;
                            svg {
                                height: 1.25rem;
                                max-height: 1.25rem;
                            }
                            color: white;
                        }
                    }
                    .tone {
                        grid-row: 2;
                        padding: 0 .5rem;
                        padding-bottom: .25rem;
                        background-color: #282828;
                        border-radius: 50px;
                        min-height: 2.25rem;
                        transform-origin: center;
                        font-size: 1.5rem;
                        margin: .25rem;
                        justify-content: center;
                        justify-self: flex-start;
                        position: relative;
                        &:after {
                            content: attr(data-tone);
                            color: white;
                            font-weight: 900;
                            margin-bottom: 1rem;
                            margin-top: .25rem;
                            font-size: .6rem;
                            margin-left: .25rem;
                            margin-right: .5rem;
                            left: 0;
                            position: relative;
                            min-width: 2.5rem;
                        }
                        &:before {
                            content: attr(data-score);
                            color: white;
                            font-weight: 900;
                            position: absolute;
                            top: 1rem;
                            left: 2.5rem;
                            font-size: .75rem;
                            margin-left: .25rem;
                        }
                    }
                }
            }
        }
        .editor {
            grid-row: span 2;
        }
    }
    .tui-image-editor-header-logo {
        display: none;
    }
    .tui-image-editor-header-buttons {
        div,
        button {
            border-radius: 5px;
        }
        button {
            background-color: #5d5d88 !important;
            border: none !important;
        }
    }
}