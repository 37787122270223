@import '../../app/theme/theme-variables.scss';

@mixin border-opacity($color,
  $opacity: 0.3) {
  /* The Fallback */
  border-color: rgba($color, $opacity);
}

@mixin background-opacity($color,
  $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

section.nopad {
  padding: unset;
}

section.contact {
  padding: 2rem;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;

      .contact-info .intro {
        width: 100%;
        max-width: unset;
      }
    }

  }
}

section.service-interest .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 450px;
  margin: 0 2rem;
  padding: 1rem;
  box-sizing: content-box;
  margin-top: -1rem;
  margin-bottom: 2rem;
  @include background-opacity($kd-purple, 0.2);
  position: relative;
  flex-direction: row;



  &:after {
    position: absolute;
    top: -10px;
    left: calc(25% - .5rem - 5px); // half width of triangle minus half gap from parent
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid;
    @include border-opacity($kd-purple, 0.2);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  .service {
    padding: unset;
    border-radius: 5px;

    &.selected.button:hover {
      box-shadow: 0 0 0 3px rgba(206, 32, 49, .4);
    }

    &.selected.button:active {
      box-shadow: 0 0 0 3px transparent;
    }

    .card {
      border: 2px solid transparent;
      cursor: pointer;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding-right: 1rem;

        .img-con {
          width: 25px;
          height: 25px;
        }

        .content,
        .title {
          grid-column: 2;
        }

        .title {
          padding: unset;
          font-size: inherit;
        }

        .content {
          display: none;
        }
      }
    }
  }

  .selected .card {
    @include border-opacity($kd-purple, .75);
    position: relative;



    .img-con:after {
      background-color: $kd-purple;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .1;
      content: "";
    }
  }
}

.react-tabs {
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.react-tabs__tab {
  white-space: nowrap;
  color: $kd-purple;
  z-index: 0;

  .button {
    color: inherit;
  }

  &.react-tabs__tab--selected {

    .button {
      cursor: default;
      background-color: $kd-purple;
      color: white;
      position: relative;

      &:before {
        display: block;
        position: absolute;
        background-color: $kd-purple;
        bottom: -.3rem;
        left: -5%;
        content: "";
        width: 110%;
        height: 35px;
        border-radius: 10px;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        z-index: -1;
      }
    }
  }
}

.react-tabs__tab-list {
  list-style: none;
  display: flex;
  gap: 1rem;
  max-width: 1024px;
  width: 100%;
  box-sizing: border-box;
  @include background-opacity($kd-purple, .01);
  margin: unset;
  border-radius: 10px;
  border-bottom-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  padding: 1rem 2rem;
  padding-bottom: unset;
  border-bottom: 1px solid $kd-purple;

  @media screen and (max-width: 1024px) {
    padding: 1rem 1rem;
    padding-bottom: unset;
  }

  @media screen and (max-width: 550px) {
    justify-content: center;
  }
}

.contact-info {
  background-color: $kd-purple;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    border-radius: 10px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .intro {
    max-width: 200px;

    h2 {
      margin-top: unset;
    }
  }

  .email {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: white;
    }

  }

  i {
    margin-right: .5rem;
    font-size: 1.5rem;
  }
}

.form {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  grid-gap: .5rem 1rem;
  max-width: 1024px;
  background-color: white;
  border-radius: 10px;
  border-top-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;

  @media screen and (max-width: 550px) {
    display: flex;
  }

  h2 {
    grid-column: 1 / span 2;
    margin-top: unset;
    margin-bottom: unset;
    color: $kd-purple;
  }

  p {
    color: $kd-purple;
  }

  input,
  textarea {
    background-color: transparent;
    border: unset;
    border-bottom: solid 2px;
    @include border-opacity($kd-purple, 0.25);
    font-family: inherit;
    font-size: inherit;
    transition: border-color 250ms ease-in-out;
    max-height: 40px;
    min-height: 40px;

    &:focus {
      @include border-opacity($kd-purple, 1);
    }

    &:focus-visible {
      outline: unset;
    }
  }

  .radio-container {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    align-items: center;
    grid-column: 1 / span 2;
    margin-top: -1.5rem;
    margin-bottom: 1rem;

    input {
      margin-right: .5rem;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    label {
      cursor: pointer;
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }
  }

  textarea {
    grid-column: 1 / span 2;
    max-height: unset;
    min-height: 80px;
    max-width: 100%;
  }
}

.quote.form {
  .radio-container {
    margin-top: -.5rem;
    margin-bottom: 1rem;
  }

  .upload-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column: 1 / span 2;

    button.quotes {
      align-self: flex-end;
    }

    p {
      margin: unset;
    }
  }

  button.submit {
    grid-column: 2;
  }

  input[name=subject] {
    grid-column: 1 / span 2;
  }

  .upload-label {
    text-align: center;
  }

  #file-upload {
    display: none;
  }
}