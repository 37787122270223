.App.posts {
    background-color: #ecf0f5;
    font-size: 10pt;
    header {
        grid-column: span 2;
    }
    .post-container {
        display: grid;
        grid-template-columns: 900px 1fr;
        grid-gap: 1rem;
        max-width: 1200px;
        margin: auto;
        .post {
            position: relative;
            box-sizing: border-box;
            border-radius: 2px;
            overflow: hidden;
            background-color: white;
            max-width: 1000px;
            width: 100%;
            position: relative;
            padding: 1rem;
            padding-top: 2rem;
            margin: auto;
            margin-top: 1rem;
            grid-column: 1;
            .pane-title {
                background-color: #282828;
                color: white;
                width: 100%;
                height: 2rem;
                position: absolute;
                left: 0;
                top: 0;
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        padding: .5rem 1rem;
                        h1 {
                            font-size: 10pt;
                            margin: 0;
                            font-weight: 600;
                        }
                    }
                }
            }
            p {
                color: rgba(0, 0, 0, .8);
                img {
                    margin-bottom: 3vh;
                }
            }
            video,
            iframe {
                width: 900px;
                min-height: 500px;
                height: auto;
                margin-left: -1rem;
                margin-bottom: 3vh;
            }
            p+h1,
            p+h2,
            p+h3,
            p+h4,
            p+h5,
            p+h6 {
                margin-top: 5vh;
            }
            img {
                width: calc(100% + 2rem);
                height: auto;
                margin-left: -1rem;
                margin-right: -1rem;
            }
        }
        .sidebar {
            width: 100%;
            box-sizing: border-box;
            ul {
                margin-top: 1rem;
                background-color: white;
                margin-bottom: 1rem;
                padding: 0;
                list-style: none;
                border-radius: 2px;
                overflow: hidden;
                li {
                    border-bottom: 1px solid rgba(0, 0, 0, .1);
                    padding: .5rem 1rem;
                    &:first-of-type {
                        background-color: #282828;
                        color: white;
                    }
                    &:last-of-type {
                        border-bottom: unset;
                    }
                }
            }
        }
    }
}