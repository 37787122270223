.App.blog {
    background-color: #ecf0f5;
    font-size: 10pt;

    header {
        grid-column: span 2;
    }

    section.blog {
        display: grid;
        grid-template-columns: 8fr 4fr;
        grid-gap: 1rem;
        max-width: 1200px;
        margin: auto;

        @media screen and (max-width: 1000px) {
            grid-template-columns: 8fr 2fr;
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        h3~h4 {
            margin-top: unset;
        }

        h3 {
            margin-bottom: unset;
        }

        .container {
            position: relative;
            box-sizing: border-box;
            border-radius: 2px;
            overflow: hidden;
            background-color: white;
            max-width: 1000px;
            width: 100%;
            position: relative;
            padding: 1rem;
            padding-top: 2rem;
            margin: auto;
            margin-top: 1rem;
            grid-column: 1;

            .pane-title {
                background-color: #282828;
                color: white;
                width: 100%;
                height: 2rem;
                position: absolute;
                left: 0;
                top: 0;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        padding: .5rem 1rem;

                        h1 {
                            font-size: 10pt;
                            margin: 0;
                            font-weight: 600;
                        }
                    }
                }
            }

            .featured-image img {
                margin-left: -1rem;
                width: calc(100% + 2rem);
            }

            .content {

                .button-con {
                    justify-content: flex-start;
                    display: flex;

                    .button {
                        margin: .25rem 0;
                        margin-right: .25rem;
                        padding: .25rem .5rem;
                        display: flex;
                        flex-wrap: nowrap;
                        white-space: nowrap;
                        flex-direction: row;
                        align-items: center;
                        text-decoration: none;

                        i {
                            margin-right: .3rem;
                        }
                    }
                }

                h1 {
                    margin-top: 1.5rem;
                }

                p img {
                    max-width: calc(100% + 2rem);
                    margin-left: -1rem;
                }

                .card-stack {
                    position: relative;

                    &:after {
                        content: "Hover to flip through images";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 1rem 3rem;
                        background-color: rgba(255, 255, 255, .5);
                        border-radius: 10px;
                        font-weight: 700;
                        z-index: 11;
                    }

                    &:hover:after {
                        display: none;
                    }
                }


                .card-stack p {
                    color: rgba(0, 0, 0, .8);
                    display: flex;

                    img:last-of-type {
                        z-index: 10;
                    }

                    &:hover {
                        img {
                            filter: saturate(0);

                            &:hover {
                                filter: saturate(1);
                            }
                        }

                        img:first-of-type {
                            margin-right: unset;
                        }
                    }

                    img {
                        filter: saturate(1);
                        max-width: 50%;
                        margin-left: -10rem;
                        transition: filter 250ms ease-in-out;

                        &:first-of-type {
                            margin-left: unset;
                            margin-right: 11rem;
                        }

                        &:last-of-type {
                            margin-right: unset;

                            &:hover {
                                margin-right: 11rem;
                            }
                        }

                        &:hover {
                            z-index: 10;
                            margin-right: 11rem;

                            &:first-of-type {
                                margin-left: unset;
                                margin-right: unset;

                                &:hover {
                                    margin-right: 11rem;
                                }
                            }

                            &:last-of-type {
                                margin-right: unset;

                                &:hover {
                                    margin-left: 1rem;
                                }
                            }
                        }
                    }
                }

                video,
                iframe {
                    width: 100%;
                    min-height: 500px;
                    height: auto;
                }

                p+h1,
                p+h2,
                p+h3,
                p+h4,
                p+h5,
                p+h6 {
                    margin-top: 5vh;
                }

                img {
                    width: calc(100% + 2rem);
                    height: auto;
                }
            }
        }

        .sidebar {
            width: 100%;
            box-sizing: border-box;

            ul {
                margin-top: 1rem;
                background-color: white;
                margin-bottom: 1rem;
                padding: 0;
                list-style: none;
                border-radius: 2px;
                overflow: hidden;

                &.badges {
                    margin-top: 1rem;
                    padding-left: .75rem;
                    padding-right: .75rem;
                    justify-content: flex-start;

                    li {
                        &:first-of-type {
                            margin-bottom: .5rem;
                            margin-left: -1rem;
                            margin-right: -1rem;
                        }

                    }

                    .badge {
                        padding: unset;
                        border-bottom: unset;
                    }
                }

                li {
                    border-bottom: 1px solid rgba(0, 0, 0, .1);
                    padding: .5rem 1rem;
                    position: relative;

                    &:first-of-type {
                        background-color: #282828;
                        color: white;
                        width: 100%;
                    }

                    &:last-of-type {
                        border-bottom: unset;
                    }


                }
            }
        }
    }
}