.blog.card,
.blog.card a {
  display: flex;
  text-decoration: none;
  justify-content: flex-start;

  .title-con {
    padding: 1rem;

    .title {
      padding: 0;
    }

    .button-con {
      display: flex;
      justify-content: flex-start;

      .button {
        margin: .25rem 0;
        margin-right: .25rem;
        padding: .25rem .25rem;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        flex-direction: row;
        align-items: center;

        i {
          margin-right: .3rem;
        }
      }
    }
  }

  .result {
    padding: 1rem;
    margin: 1rem;
    background-color: white;
    color: #282828;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 5px 5px -5px rgba(93, 93, 136, .4);
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .featured-image {
      width: calc(100% + 2rem);
      margin: -1rem;
      margin-bottom: 0;
    }


    .title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0;
    }

    .excerpt p {
      opacity: .8;
      margin: 0;
    }
  }
}