.print-badge {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fff;
    color: black;
    padding: 0.75rem 1rem;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    svg {
        width: 1rem;
        margin-right: 0.5rem;
    }

    span:last-child {
        font-size: 0.55rem;
    }

    @media print {
        display: none;
    }
}

#ResumePage {
    background-color: black;

    @media print {
        background-color: transparent;
        padding: unset;
    }

    .printer-page-break {
        page-break-after: always;
    }
}

html {
    @media print {
        font-size: 11pt;
    }
}

section.footer,
section.header {
    @media print {
        display: none;
    }
}

.resume-container {
    max-width: 1000px;
    margin: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;

    @media screen and (max-width: 768px) {
        margin-top: 0;
        display: grid;
        grid-template-columns: 1fr;

        .body {
            .left:nth-of-type(1) {
                grid-row: 1;
            }

            .left:nth-of-type(2) {
                grid-row: 2;
            }

            .right:nth-of-type(1) {
                grid-row: 3;
            }

            .right:nth-of-type(2) {
                grid-row: 4;
            }
        }

        .head,
        .body {
            grid-template-columns: 1fr !important;
        }

        .head {
            & > .col {
                padding: 1rem;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .resume-flex-con {
            flex-direction: column;

            h3:first-child {
                margin-bottom: 0.5rem;
            }

            h3:last-child {
                font-size: 0.83rem;
            }
        }
    }

    @media print {
        margin: 0;
    }

    h1,
    h2,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    p,
    li {
        font-size: 0.9rem;
    }

    .head,
    .body {
        display: grid;
        grid-template-columns: 3fr 7fr;
        grid-gap: 1rem;
        padding: 0;
        background-color: #131318;

        @media print {
            background-color: transparent;
            color: #131318;
            padding: unset;
        }

        color: white;

        h3:first-child {
            margin-top: 0;
        }
    }

    .head {
        @media print {
            padding: unset;
            padding-bottom: 1rem;
        }
    }

    .head .col:first-of-type {
        padding-left: 1rem;
    }

    .right {
        background-color: white;
        color: black;
        padding: 1rem;
        padding-top: 1.25rem;
        @media print {
            padding-top: 0.5rem;
            background-color: transparent;
        }
    }

    .left {
        padding-right: 0.5rem;
        padding: 1rem;
        padding-top: 0;

        @media print {
            margin-top: -1rem;
        }

        h6 + p {
            margin: 0;
            margin-bottom: 1rem;
        }
    }

    .contents {
        background-color: white;
        color: #131318;
        margin-top: -1rem;
        height: 100%;

        @media print {
            background-color: transparent;
        }

        ul {
            list-style: square;
            padding-left: 1.5rem;
            margin-top: 0.5rem;

            &::marker {
                height: 5px;
            }
        }

        h5 {
            margin-top: 1.25rem;
        }
    }

    .link.white {
        color: white;
        display: block;
        margin-top: 0.65rem;

        @media print {
            display: none;
        }
    }

    .resume-flex-con {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }

        h3 {
            margin: 0;
        }
    }

    .button-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .head-button {
        background-color: white;
        color: black;
        padding: 0.5rem;
        display: flex;
        border-bottom: 0px solid #131318;
        transition: border-bottom, margin-top;
        text-decoration: none;
        margin-top: 5px;
        margin-bottom: 8px;
        cursor: pointer;

        img {
            height: 1.25rem;
            margin-right: 0.75rem;
        }

        .print-only {
            font-weight: 600;
            color: black;

            @media print {
                width: auto;
                margin-left: 0.5rem;
            }
        }

        &:hover {
            border-bottom: 5px solid rgba(0, 0, 0, 0.2);
            margin-top: -5px;
        }

        &:first-of-type:hover {
            margin-top: 0;
        }
    }

    .software.badges {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        padding: unset;
        justify-content: flex-start;

        h3 {
            width: 100%;
        }

        .badge {
            grid-column: span 2;
            width: auto;
            position: relative;

            .badge1 {
                position: relative;
                z-index: 10;

                @media print {
                    filter: invert(1) brightness(1.1);
                }
            }

            .badge2 {
                position: absolute;
                top: 0;
                left: 0;

                @media print {
                    display: none;
                }
            }

            &:hover .badge1 {
                opacity: 0;
            }
        }
    }

    .lang-sec {
        display: flex;
        flex-wrap: wrap;

        .lang {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 2rem 1.5rem 1fr;
            margin-bottom: 1rem;
            padding-left: 0.5rem;

            @media screen and (max-width: 600px) {
                padding-right: 0;
                padding-left: 0;
            }

            @media print {
                grid-template-rows: 1.5rem;
            }

            h5 {
                margin: 0;
                font-size: 16px;
            }

            .badge {
                grid-column: span 2;
                margin-right: auto;
                margin-bottom: 0.5rem;
                width: auto;
                height: 2rem;
                position: relative;
                margin-left: -0.5rem;

                @media print {
                    filter: invert(1) brightness(1.1);

                    @media print {
                        grid-column: 1;
                        grid-row: 1;
                    }
                }
            }

            .years {
                margin-left: auto;

                @media print {
                    grid-column: 2;
                    grid-row: 1;
                }
            }

            .exp {
                @media print {
                    display: none;
                }
            }

            .resume-bars {
                background-color: green;
                height: 3px;
                width: 33%;
                grid-column: span 2;
                position: relative;
                z-index: 0;

                @media print {
                    display: none;
                }

                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 3px;
                }

                &:before {
                    background-color: rgba(255, 255, 255, 0.1);
                    width: calc(100% - 1px);
                    left: calc(100% + 1px);

                    @media print {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }

                &.expert:before,
                &.advanced:before {
                    background-color: green;

                    @media print {
                        background-color: black;
                    }
                }

                &:after {
                    background-color: rgba(255, 255, 255, 0.1);
                    width: 100%;
                    left: calc(200% + 1px);

                    @media print {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }

                &.expert:after {
                    background-color: green;

                    @media print {
                        background-color: black;
                    }
                }
            }
        }
    }

    .about h3,
    .education h3,
    .fluency-top h3,
    .software h3 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .fluency-top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 1rem;
    }

    .education {
        padding-left: 0.5rem;

        h6,
        h3 {
            margin-left: -0.5rem;
        }
    }

    .about {
        p {
            margin-left: 0.5rem;

            @media screen and (max-width: 600px) {
                margin-right: 0.5rem;
            }
        }
    }
}
