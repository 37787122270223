.header {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    background-color: white;
    height: 4rem;

    .container {
        max-width: 1200px;
        width: 100%;
        padding: 10px 1rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        position: relative;

        @media screen and (max-width: 1000px) {
            .logo {
                max-width: 1.5rem;
            }
        }

        @media screen and (max-width: 600px) {
            .cheesburg {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 1.5rem;
                width: 1.5rem;
                max-height: 1.5rem;
                overflow: hidden;
                position: relative;
                cursor: pointer;

                img {
                    position: absolute;
                    height: 1.5rem;
                    width: 1.5rem;
                    opacity: 0;

                    &:first-of-type {
                        opacity: 1;
                    }
                }
            }

            .cheesburg.open {
                img {
                    $base-speed: 100ms;
                    animation: open-burg $base-speed steps(3) forwards;

                    &:first-of-type {
                        animation-delay: $base-speed*1;
                    }

                    &:nth-of-type(2) {
                        animation-delay: $base-speed*1;
                        animation-duration: $base-speed*4;
                    }

                    &:nth-of-type(3) {
                        animation-delay: $base-speed*5;
                        animation: keep-burg $base-speed steps(3) forwards;
                    }

                    &:last-of-type {
                        animation: keep-close-burg $base-speed steps(3) forwards;
                        animation-delay: $base-speed*5;
                    }
                }

                &+ul {
                    transform: translateY(0);
                    z-index: 100;
                }
            }

            ul {
                transform: translateY(-100%);
                z-index: -1;
                padding: 1rem;
                padding-top: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: end;
                position: absolute;
                right: 0;
                top: 100%;
                background-color: white;

                a {
                    transition: transform 200ms ease-in-out;
                    text-align: right;

                    &:hover {
                        transform: translateX(-.25rem);
                    }
                }
            }
        }

        .logo {
            margin-right: auto;
        }

        a {
            padding: .25rem .5rem;
            text-decoration: none;
            font-weight: 700;
            opacity: .8;
            white-space: nowrap;

            &:hover {
                font-weight: 700;
                opacity: 1;
            }
        }

        .fas {
            width: 10px;
            height: 10px;
        }
    }

    @media print {
        display: none;
    }
}

@keyframes open-burg {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes keep-burg {
    0% {
        opacity: 1;
    }

    100% {
        opacity: .15;
    }
}

@keyframes keep-close-burg {
    0% {
        transform: scale(1.5);
        transform-origin: center;
        opacity: 0;
    }

    100% {
        transform: scale(1.0);
        transform-origin: center;
        opacity: 1;
    }
}