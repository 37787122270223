.App.filter {
  section.cta {
    margin-top: 1rem;
  }

  section.portfolio-cards {
    box-sizing: border-box;

    .container {
      margin-top: 1rem;
      display: grid;
      width: 100%;
      position: relative;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
      }
    }
  }
}