@import '../../app/theme/theme-variables.scss';

.card,
.card>a {
  background-color: white;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: inherit;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &.button {
    padding: unset;
    color: inherit;
    margin: unset;

    .badges {
      justify-content: flex-start;

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  &.purp {
    background-color: rgba(93, 93, 136, .1);
    color: #25252b;
  }

  .title,
  .subtitle {
    padding: 1rem;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
  }

  .title+.subtitle {
    margin-top: -1rem;
    padding-top: unset;
  }

  .content {
    padding: 1rem;
    padding-top: 0;
    margin-top: 0;
  }

  .img-con {
    overflow: hidden;
    max-width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card {
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, .1);
}

.card a {
  text-decoration: none;
  color: unset;

  &:hover .subtitle {
    animation: color-swap 1000ms infinite;
  }
}

.intro.card {
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(93, 93, 136, .1);
  color: #25252b;

  .title,
  .subtitle {
    text-align: center;
    grid-column: 1 / span all;
  }

  &:last-of-type {
    @media screen and (max-width: 768px) {
      grid-column: 1 / span all;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  span {
    font-weight: 700;
    font-size: 18pt;

    &.compact {
      font-size: 10pt;
    }
  }
}

@keyframes color-swap {
  0% {
    color: $kd-purple;
  }

  50% {
    color: $kd-navy;
  }

  100% {
    color: $kd-purple;
  }

}